import React from 'react'

export const Twitter = () => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1892" height="1892" viewBox="0 0 1892 1892">
        <defs>
            <clipPath id="clip-Prancheta_4">
            <rect width="1892" height="1892"/>
            </clipPath>
        </defs>
        <g id="Prancheta_4" data-name="Prancheta – 4" clipPath="url(#clip-Prancheta_4)">
            <g id="twitter" transform="translate(134 239)">
            <g id="Grupo_23" data-name="Grupo 23" transform="translate(0 48)">
                <path id="Caminho_18" data-name="Caminho 18" d="M1623.384,204.149a693.878,693.878,0,0,1-191.762,52.557c68.994-41.193,121.652-105.926,146.409-183.95-64.327,38.352-135.35,65.443-211.04,80.56A333.5,333.5,0,0,0,1123.889,48C939.635,48,791.3,197.554,791.3,380.9c0,26.38,2.232,51.745,7.711,75.893C522.324,443.294,277.5,310.684,113.028,108.674,84.315,158.492,67.472,215.513,67.472,276.9c0,115.26,59.355,217.432,147.829,276.584-53.47-1.015-105.926-16.538-150.366-40.99v3.652c0,161.73,115.362,296.065,266.641,327.01-27.09,7.407-56.616,10.958-87.257,10.958-21.307,0-42.817-1.218-63.008-5.682,43.121,131.8,165.484,228.694,310.98,231.84A668.717,668.717,0,0,1,79.647,1222.214c-27.293,0-53.47-1.218-79.647-4.566C147.424,1312.718,322.14,1367,510.554,1367c612.422,0,947.245-507.308,947.245-947.042,0-14.712-.507-28.917-1.218-43.02C1522.633,330.063,1578.133,271.52,1623.384,204.149Z" transform="translate(0 -48)" fill="#4605ff"/>
            </g>
            </g>
        </g>
    </svg>
)